<script>
    import Lazy from 'svelte-lazy';
</script>

<style>
    iframe {
        width: 440px;
        height: 240px;
        border: 0px;
        margin: auto;
    }
    @media only screen and (max-width: 450px) {
        iframe {
            height: 540px;
        }
    }
</style>

<div style="text-align: center;">...or try now:</div><br />
<Lazy height={240}>
    <!-- svelte-ignore a11y-missing-attribute -->
    <div style="display: flex;">
        <iframe src="https://link.epicapp.ru/example-wheel" />
    </div>
</Lazy>
<div style="text-align: center;">
    <br />This is only simple preview of app functionality
    <p>Please, consider downloading full featured mobile app</p>
</div>