<script>
    export let appStoreLink = undefined, playStoreLink = undefined;
</script>

<div style="margin:auto; padding: 0 40px 40px 40px;">
    <div style="margin: auto; display:flex; justify-content: center; align-items: center;">
        {#if appStoreLink}
            <a href={appStoreLink} target="_blank" rel="noopener">
                <img
                    style="height:50px; padding:0px 10px; object-fit: contain;"
                    alt="Get it on App Store"
                    src="./images/en_badge_app_store.svg" />
            </a>
        {/if}
        {#if playStoreLink}
            <a href={playStoreLink} target="_blank" rel="noopener">
                <img
                    style="height:50px; padding:0px 10px; object-fit: contain;"
                    alt="Get it on Google Play"
                    src="./images/en_badge_web_generic.png" />
            </a>
        {/if}
    </div>
</div>