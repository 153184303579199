<script>
    import SimpleModal from "./SimpleModal.svelte";
    import ContactForm from "./ContactForm.svelte";

    let name = "", email = "", subject = "";
    let modalText = "", modalHeader = "", open = false;
    let feedbackUpload = false;

    function showModal(header, text) {
        modalHeader = header;
        modalText = text;
        open = true;
    }

    function clearForm() {
        name = "";
        email = "";
        subject = "";
    }

    async function handleSubmit(event) {
        if (subject.length > 0) {
            if (feedbackUpload) {
                return;
            }
            feedbackUpload = true;
            const url =
                "https://us-central1-daily-decision-wheel.cloudfunctions.net/feedback";
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    subject: "[DECISION WHEEL SUPPORT] " + subject,
                }),
            });
            const data = await response.json();
            feedbackUpload = false;
            if (data.status == "ok") {
                showModal("Success", "Your request submited");
                clearForm();
            } else {
                showModal("Error", "Something went wrong, please try again later");
            }
        } else {
            showModal("Error", "Please, specify the subject");
        }
    }

</script>

<main>
    <form on:submit|preventDefault={handleSubmit}>
        <ContactForm bind:name bind:email bind:subject bind:feedbackUpload header="Support:" />
    </form>
    <!--  -->
    <SimpleModal bind:open bind:modalHeader bind:modalText />
</main>