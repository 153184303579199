<script>
	import "chota";
	import Lazy from 'svelte-lazy';
	import Header from "./Header.svelte";
	import DownloadApp from "./DownloadApp.svelte";
	import TryNow from "./OrTryNow.svelte";
	import Support from "./Support.svelte";
</script>

<Header />
<DownloadApp
	appStoreLink="https://apps.apple.com/app/apple-store/id1434195334?pt=1348653&ct=site&mt=8"
	playStoreLink="https://play.google.com/store/apps/details?id=ru.epicapp.wheel&referrer=utm_source%3Depicapp%26utm_medium%3Dshare%26utm_term%3Dsite%26anid%3Dadmob" />
<TryNow />
<Lazy height={600}>
	<Support />
</Lazy>