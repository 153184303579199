<script>
    import { Button, Modal, Card } from "svelte-chota";
    export let open = false,
        modalHeader,
        modalText,
        buttonTitle = "Ok";
</script>

<Modal bind:open>
    <Card>
        <h4 slot="header">{modalHeader}</h4>
        <p>{modalText}</p>
        <div slot="footer" class="is-right">
            <Button primary on:click={() => (open = false)}>
                {buttonTitle}
            </Button>
        </div>
    </Card>
</Modal>