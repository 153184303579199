<script>
    import { Field, Input, Button } from "svelte-chota";
    export let name = "",
        email = "",
        subject = "",
        header = "Contact Me:",
        feedbackUpload = false;
</script>

<style>
    .container {
        margin: auto;
        min-width: 450px;
        max-width: 600px;
    }
    @media only screen and (max-width: 768px) {
        .container {
            min-width: 0px;
            width: 100%;
        }
    }
</style>

<div style="height: 120px; line-height:120px; text-align: center;">
    <h2>
        {@html header}
    </h2>
</div>
<div style="height: 600px; display: flex; text-align: center;">
    <div class="container">
        <Field id="name" label="Your Name">
            <Input bind:value={name} placeholder="Name" />
        </Field>
        <Field id="email" label="Your E-mail">
            <Input
                bind:value={email}
                placeholder="Enter E-mail if you want to get feedback" />
        </Field>
        <Field id="subject" label="Subject">
            <Input
                textarea
                bind:value={subject}
                placeholder="Questions or suggestions"
                style="min-height:170px" />
        </Field>
        <Button type="submit" style="width: 200px" primary>
            <h3>{feedbackUpload ? 'Sending...' : 'Send'}</h3>
        </Button>
    </div>
</div>
